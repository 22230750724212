import { type ShallowRef } from "vue";
import { useStreamlabsFetch } from "~/lib/streamlabs-api";
import { openBrowserTo } from "~/lib/bindings";
import IconTrovo from "~/components/icons/IconTrovo.vue";
import { equalityRef } from "~/lib/util";

// todo: scenario -- user has multistream enabled via API, but OBS is not pointing to it

export type MulitstreamChatSupportedPlatform =
  | "twitch"
  | "youtube"
  | "facebook"
  | "tiktok"
  | "twitter"
  | "trovo";

export enum RstToPlatformType {
  "twitch_account" = "twitch",
  "facebook_account" = "facebook",
  "youtube_account" = "youtube",
  "trovo_account" = "trovo",
  "twitter_account" = "twitter",
  "tiktok_account" = "tiktok",
}

export enum PlatformTypeToRst {
  "twitch" = "twitch_account",
  "facebook" = "facebook_account",
  "youtube" = "youtube_account",
  "trovo" = "trovo_account",
  "twitter" = "twitter_account",
  "tiktok" = "tiktok_account",
}

export const useMultistreamStore = defineStore(
  "multistream",
  () => {
    console.log("multistream.store");
    const obs = useObsStore();
    const auth = useAuthStore();
    const api = useApiStore();
    const app = useAppStore();

    auth.socket.on("account_permissions_required", (e) => {
      for (const item of e.message) {
        auth.tryLogin(item.url).then(() => {
          const chatDock = obs.getDockByTitle("Streamlabs: Chat");

          if (chatDock) {
            chatDock.executeJavascript(`window.location.reload(true);`);
          }
        });
        break;
      }
    });

    const enable = () => {
      return useStreamlabsFetch<RstUserSettingsResponse>(
        `/api/v5/obs-plugin/rst/user/settings`,
        {
          method: "POST",
          body: {
            enabled: true,
          },
        },
      );
    };

    const disable = () => {
      return useStreamlabsFetch<RstUserSettingsResponse>(
        `/api/v5/obs-plugin/rst/user/settings/disable`,
        {
          method: "POST",
        },
      );
    };

    type PlatformConfig = {
      id: string;
      label: string;
      icon: string | ShallowRef;
      iconClass?: string;
      buttonClass?: string;
      switchClass?: string;
    };

    const platformConfigs = ref<Record<RstToPlatformType, PlatformConfig>>({
      twitch: {
        id: "twitch",
        label: "Twitch",
        icon: "simple-icons:twitch",
        iconClass: "!text-twitch",
        buttonClass: "!bg-twitch !text-black",
        switchClass: `data-[state=checked]:!bg-twitch`,
      },
      youtube: {
        id: "youtube",
        label: "YouTube",
        icon: "simple-icons:youtube",
        iconClass: "!text-youtube",
        buttonClass: "!bg-youtube !text-white",
        switchClass: `data-[state=checked]:!bg-youtube`,
      },
      facebook: {
        id: "facebook",
        label: "Facebook",
        icon: "simple-icons:facebook",
        iconClass: "!text-facebook",
        buttonClass: "!bg-facebook !text-white",
        switchClass: `data-[state=checked]:!bg-facebook`,
      },
      trovo: {
        id: "trovo",
        label: "Trovo",
        icon: shallowRef(IconTrovo),
        iconClass: "!text-trovo",
        buttonClass: "!bg-trovo !text-white",
        switchClass: `data-[state=checked]:!bg-trovo`,
      },
      twitter: {
        id: "twitter",
        label: "X (Twitter)",
        icon: "simple-icons:x",
        iconClass: "!text-twitter",
        buttonClass: "!bg-twitter !text-black",
        switchClass: `data-[state=checked]:!bg-neutral-600`,
      },
      tiktok: {
        id: "tiktok",
        label: "TikTok",
        icon: "simple-icons:tiktok",
        iconClass: "!text-[#ff0050]",
        buttonClass: "",
        switchClass: "data-[state=checked]:!bg-[#ff0050]",
      },
    });

    const relays = computed(() => {
      return (api.multistream.user.targets.data || []).filter(
        (target) => target.platform === "relay",
      );
    });

    const destinations = equalityRef<Destination[]>([]);

    watchEffect(() => {
      let items: Destination[] = [];

      if (
        api.multistream.user.platforms.data &&
        api.multistream.user.profile.data &&
        api.platformInfo.data
      ) {
        for (const [k, v] of Object.entries(
          api.multistream.user.platforms.data,
        ) as Entries<typeof api.multistream.user.platforms.data>) {
          const platformInfo = api.platformInfo.data.platform_info[k];
          const enabled = !!api.multistream.user.profile.data[k];
          const available =
            !v.available_for ||
            Object.values(v.available_for).includes("obs-plugin");

          items.push({
            type: "platform",
            platform_type: RstToPlatformType[k],
            platform_username: platformInfo?.username,
            platform_id: platformInfo?.id,
            enabled,
            connected: v.connected,
            valid: !!v.has_permission,
            available,
          });
        }
      }

      items = useOrderBy(items, ["connected", "valid"], ["desc", "desc"]);

      for (const relay of relays.value) {
        const sk = relay.streamKey;
        const streamKey = sk.substring(sk.lastIndexOf("/") + 1);
        const streamUrl = sk.substring(0, sk.lastIndexOf("/"));

        items.push({
          type: "relay",
          enabled: relay.enabled,
          nickname: relay.label,
          relay_id: relay.id,
          streamKey,
          streamUrl,
        });
      }

      destinations.value = items;
    });

    const enabledDestinations = computed(() => {
      return destinations.value.filter((item) => {
        if (item.type === "platform") {
          return item.enabled && item.valid;
        }

        return item.enabled;
      });
    });

    const enabledPlatforms = computed(() => {
      const enabledPlatforms: RstToPlatformType[] = [];

      for (const destination of enabledDestinations.value) {
        if (destination.type === "platform") {
          enabledPlatforms.push(destination.platform_type);
        }
      }

      return enabledPlatforms;
    });

    return {
      enable,
      disable,

      destinations,
      enabledDestinations,
      enabledPlatforms,
      platformConfigs,

      connectPlatform: async (platform: PlatformTypeToRst) => {
        // todo: what if no oauth_token?
        const u = new URL(
          `https://streamlabs.com/slobs/merge/${auth.authResponseParams?.oauth_token}/${platform}`,
        );

        const port = (await auth.startResponseServer()) || 1234;

        u.searchParams.set("port", port.toString());

        openBrowserTo(u.toString());

        try {
          await auth.waitForQueryParams<{
            platform: string;
            platform_username: string;
            platform_token: string;
            platform_id: string;
          }>();

          api.multistream.user.platforms.refresh();
          // api.multistream.user.settings.refresh();
          api.multistream.user.profile.refresh();
          api.multistream.user.targets.refresh();
          api.platformInfo.refresh();

          await obs.bringToFront();
          await app.bringToFront();
        } catch (err) {
          console.error(err);
        }
      },
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
